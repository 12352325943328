
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    nested: Boolean,
    name: String,
    profilepic: String,
    area: String,
    role: String,
    time: String,
    message: String,
  },
})
export default class Comment extends Vue {}
